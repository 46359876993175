import { lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { getIsQqDomain } from 'utils/common';
const Login = lazy(async () => await import('pages/Login'));
const Exception = lazy(async () => await import('pages/Exception'));
const Index = lazy(async () => await import('pages/DataBoard/index'));
const MyData = lazy(async () => await import('pages/DataBoard/myData'));
const CorpusQaList = lazy(async () => await import('pages/Corpus/qaList'));
const Iframe = lazy(async () => await import('pages/Iframe'));
const CaiBlindReview = lazy(async () => await import('pages/Review/caiBlindReview'));
const QAReview = lazy(async () => await import('pages/Review/qaReview'));
const AccountApprove = lazy(async () => await import('pages/Account/approve'));
const ProjectList = lazy(async () => await import('pages/CorpusUpgraded/project'));
const TaskList = lazy(async () => await import('pages/CorpusUpgraded/task'));
const TemplateList = lazy(async () => await import('pages/CorpusUpgraded/template'));
const QaList = lazy(async () => await import('pages/CorpusUpgraded/qa'));

const ROUTER_CONFIG = [
  {
    path: '/exception-403',
    element: <Exception type='403' />,
    meta: {
      white: true,
    },
  },
  {
    path: '/exception-404',
    element: <Exception type='404' />,
    meta: {
      white: true,
    },
  },
  {
    path: '/',
    element: <Navigate to={getIsQqDomain() ? '/corpus_upgraded/project' : '/databoard'} />,
    meta: {
      white: false,
    },
  },
  {
    path: '/login',
    element: <Login />,
    meta: {
      white: true,
    },
  },
  {
    path: '/databoard',
    element: <Index />,
    meta: {
      white: false,
    },
  },
  {
    path: '/databoard/mydata',
    element: <MyData />,
    meta: {
      white: false,
    },
  },
  {
    path: '/account/approve',
    element: <AccountApprove />,
    meta: {
      white: false,
    },
  },
  {
    path: '/corpus_upgraded/project',
    element: <ProjectList />,
    meta: {
      white: false,
    },
  },
  {
    path: '/corpus_upgraded/task',
    element: <TaskList />,
    meta: {
      white: false,
    },
  },
  {
    path: '/corpus_upgraded/template',
    element: <TemplateList />,
    meta: {
      white: false,
    },
  },
  {
    path: '/corpus_upgraded/qa',
    element: <QaList />,
    meta: {
      white: false,
    },
  },
  {
    path: '/review/cai_blind_review',
    element: <CaiBlindReview />,
    meta: {
      white: false,
    },
  },
  {
    path: '/review/qa_review',
    element: <QAReview />,
    meta: {
      white: false,
    },
  },
  {
    path: '/config/wuji/:path',
    element: <Iframe />,
    meta: {
      white: false,
    },
  },
  {
    path: '*',
    element: <Navigate to='/exception-404' />,
    meta: {
      white: true,
    },
  },
];

export default ROUTER_CONFIG;
