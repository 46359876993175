import { BASE_STATUS_TYPE } from './common';

// 默认流程
export const DEFAULT_PROCESS_INFO = {
  remark: '步骤1',
  need_qa: BASE_STATUS_TYPE.YES.value,
  process_type: undefined,
  process_name: '', // 步骤名
  process_desc: '', // 步骤描述
};
